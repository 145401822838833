import { Injectable, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Subscription, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class NavigationService implements OnDestroy {

    /**
     * Event für das Scrollen zu einem Element
     */
    public scrollToSubject = new Subject<number>();

    /**
     * Prüft ob es sich um die Startseite handelt
     */
    public isStartseite = true;

    /**
     * Speicher alle Eventlistener
     */
    sub = new Subscription();

    /**
     * Gibt an ob in der Oberfläche die Klasse
     * für den transparenten Hintergrund geschrieben
     * werden soll
     */
    public transparentBackground = true;

    /**
     * Erstellt einen Eventlistener der auf einen Seitenwechsel
     * hört. Bei jedem Seitenwechsel wird der Titel für die jeweilige
     * Seite geändert und geschaut, ob es sich um die Startseite oder
     * eine andere Seite handelt.
     * @param router Router für navigations Event
     * @param title Title um den Titel für jede Seite zu ändern
     */
    constructor(public router: Router, title: Title) {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.topFunction();
                switch (event.urlAfterRedirects) {
                    case '/home': {
                        title.setTitle('Konsumentenschutzverein');
                        this.isStartseite = true;
                        this.transparentBackground = true;
                        break;
                    }
                    case '/legalnotice': {
                        title.setTitle('Konsumentenschutzverein');
                        this.isStartseite = false;
                        this.transparentBackground = false;
                        break;
                    }
                    case '/privacypolice': {
                        title.setTitle('Konsumentenschutzverein');
                        this.isStartseite = false;
                        this.transparentBackground = false;
                        break;
                    }
                    default: {
                        this.isStartseite = true;
                    }
                }
            }
        });
    }

    /**
     * Feuert das Scrollevent
     * @param elementID ID vom Element
     */
    scrollTo(elementID: number) {
        this.scrollToSubject.next(elementID);
    }

    /**
     * Scrollt die Seite immer ganz nach oben.
     */
    topFunction() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0; // For IE and Firefox
    }

    /**
     * Löscht alle Eventlistener nach dem Schließen
     */
    ngOnDestroy() {
        this.sub.unsubscribe();
    }
}
