import { Component, HostListener, OnInit } from '@angular/core';

import { NavigationService } from 'src/app/services/navigation.service';
import $ from "jquery";

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit {

  /**
   * Listener für den Scrollevent,
   * um der Navigationsleiste die richtige
   * Hintergrundfarbe und Größe zu geben
   * @param event Scroll Eve
   */
  @HostListener('window:scroll', ['$event'])
  public onWindowScroll(event: Event): void {
    if (this.navigationService.isStartseite) {
      if (window.pageYOffset > 30) {
        this.navigationService.transparentBackground = false;
      } else {
        this.navigationService.transparentBackground = true;
      }
    } else {
      this.navigationService.transparentBackground = false;
    }
  }

  constructor(public navigationService: NavigationService) {
    $(function () {
      $('.nav .nav-animation').on('click', function () {
        if ($('#navbarToggler').css('display') != 'none') {
          $('#navbarToggler').trigger("click");
        }
      });
    });
  }

  ngOnInit() {
  }

}
